<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" class="sectionLeft" no-gutters>
      <v-col cols="1" v-if="mobile"></v-col>
      <v-col v-bind:cols="mobile ? 6 : 7">
        <p v-bind:class="mobile ? 'text2 textMobile' : 'text2 textLeft'" v-html="$t('text1')"></p>
        <p class="text1 textLeft" v-html="$t('text2')" v-if="!mobile"></p>
        <p class="text2 textLeft" v-html="$t('text3')" v-if="!mobile"></p>
        <p class="text1 textLeft" v-html="$t('text4')" v-if="!mobile"></p>
      </v-col>
      <v-col v-bind:cols="mobile ? 4 : 5">
        <v-img
            v-bind:class="mobile ? 'imgPeopleMobile' : 'imgPeople'"
            :src="require('@/assets/picto/pictoPeople2.svg')"
            contain
            v-bind:height="mobile ? 200 : 500"
        />
      </v-col>
      <v-col cols="1" v-if="mobile"></v-col>
      <v-col cols="1" v-if="mobile"></v-col>
      <v-col cols="10" v-if="mobile">
        <p class="text1 textMobile" v-html="$t('text2')"></p>
        <p class="text2 textMobile" v-html="$t('text3')"></p>
        <p class="text1 textMobile" v-html="$t('text4')"></p>
      </v-col>
      <v-col cols="1" v-if="mobile"></v-col>
    </v-row>
    <v-row align="center" justify="center" class="secondPart" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 5" v-bind:class="mobile ? 'secondPartLeftMobile' : 'secondPartLeft'">
        <h3 class="titleSecondPart" v-html="$t('titleSecondPart')"></h3>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 7">
        <p v-bind:class="mobile ? 'textMobile' : 'textLeftSecondPart'" v-bind:style="mobile ? 'margin-top: 30px;' : ''" v-html="$t('textSecondPart1')"></p>
        <p v-bind:class="mobile ? 'textMobile' : 'textLeftSecondPart'" v-html="$t('textSecondPart2')"></p>
        <p v-bind:class="mobile ? 'textMobile' : 'textLeftSecondPart'" v-html="$t('textSecondPart3')"></p>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <h2 v-bind:class="mobile ? 'secondTitleMobile' : 'secondTitle'">{{ $t('secondTitle') }}</h2>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'thirdPart' : 'thirdPart'" no-gutters>
      <v-col cols="5" class="textLeftThirdPart" v-if="!mobile">
        <div class="firstStep">
          <h3 class="titleStep" v-html="$t('firstStepTitle')"></h3>
          <p class="text2" v-html="$t('stepSubTitle')"></p>
          <p class="text1" v-html="$t('firstStepText')"></p>
        </div>
        <div class="thirdStep">
          <h3 class="titleStep" v-html="$t('thirdStepTitle')"></h3>
          <p class="text2" v-html="$t('stepSubTitle')"></p>
        </div>
      </v-col>
      <v-col cols="1" v-if="mobile"></v-col>
      <v-col v-bind:cols="mobile ? 1 : 2">
        <v-img
            :src="require('@/assets/picto/separator6.svg')"
            contain
            height="600"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 9 : 5">
        <div v-if="mobile">
          <h3 class="titleStepMobile" v-html="$t('firstStepTitle')"></h3>
          <p class="text2 textMobile" v-html="$t('stepSubTitle')"></p>
          <p class="text1 textMobile" v-html="$t('firstStepText')"></p>
        </div>
        <div v-bind:class="mobile ? 'secondStepMobile' : 'secondStep'">
          <h3 v-bind:class="mobile ? 'titleStepMobile' : 'titleStep'" v-html="$t('secondStepTitle')"></h3>
          <p class="text2" v-html="$t('stepSubTitle')"></p>
          <p class="text1" v-html="$t('secondStepText')"></p>
        </div>
        <div class="secondStepMobile" v-if="mobile">
          <h3 v-bind:class="mobile ? 'titleStepMobile' : 'titleStep'" v-html="$t('thirdStepTitle')"></h3>
          <p class="text2 textMobile" v-html="$t('stepSubTitle')"></p>
        </div>
      </v-col>
      <v-col cols="1" v-if="mobile"></v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    props: ['mobile'],
    name: 'IntegrationSport',
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Integration through sport",
    "text1": "<strong> IIDRIS </strong> defines social integration as an <strong> \"action aimed at helping an isolated or marginal individual evolve into a situation characterized by satisfactory exchanges with his environment \". </strong>",
    "text2": "<strong> The National Poverty and Social Exclusion Observatory (ONPES) </strong> has helped to give visibility to more than 8 million people in situations of social exclusion through the management of studies on social, political and economic aspects of their living conditions.",
    "text3": "People in difficult social situations have more health problems than the average population. Indeed, social insecurity results from life courses made of social, professional or emotional ruptures and covers various histories and difficulties.",
    "text4": "Health problems often arise from these ruptures, from these past living conditions. They make people less able to identify and treat these health problems, which worsen and accumulate.",
    "titleSecondPart": "Act through adapted physical activities:",
    "textSecondPart1": "<strong> Adapted physical activities are provided to people with social difficulties, for the purposes of prevention, reintegration, education and / or social participation. </strong>",
    "textSecondPart2": "<strong> The Apa </strong> is not reduced to the only practice of physical activities. Indeed, we use physical activity as a support that will allow individuals to develop physical, social and psychological resources. In this sense, the practice of <strong> Apa </strong> is not an end in itself, but a means put in place to support the people and groups concerned.",
    "textSecondPart3": "<strong> The Apa </strong> is therefore fully part of the life course of individuals. <strong> The Apa </strong> is then used as a tool for individual fulfillment and expression, and as a factor of communication, affiliation and social bond.",
    "secondTitle": "Typical support offered by Vitaé, sport and health:",
    "firstStepTitle": "Initial assessment:",
    "stepSubTitle": "Assessment of physical, social, and emotional health",
    "firstStepText": "<strong> 1st step: <br> Adapted physical activity workshop <br> </strong> - Re-mobilize skills <br> - Build capacities <br> - develop skills <br> - Increase self-esteem and trust",
    "secondStepTitle": "Interim balance sheet :",
    "secondStepText": "<strong> 2nd step: <br> Referral to a sports or leisure association. <br> </strong> - promote and facilitate inclusion <br> - Reducing social boundaries <br> - Developing a common culture",
    "thirdStepTitle": "Final balance sheet :"
  },
  "fr": {
    "mainTitle": "L'insertion par le sport",
    "text1": "<strong> L’IIDRIS </strong> définit l’insertion sociale comme une <strong>\"action visant à faire évoluer un individu isolé ou marginal vers une situation caractérisée par des échanges satisfaisants avec son environnement\". </strong> ",
    "text2": "<strong>L’Observatoire national de la pauvreté et de l’exclusion sociale (ONPES)</strong> a participé à donner de la visibilité à plus de 8 millions de personnes en situation d’exclusion sociale via le pilotage d’études relatives à des aspects sociaux, politiques et économiques sur leurs conditions de vie.",
    "text3": "Les personnes se trouvant dans des situations sociales difficiles ont davantage de problèmes de santé que la population moyenne. En effet la précarité sociale résulte de parcours de vie faits de ruptures sociales, professionnelles ou affectives et recouvre des histoires et difficultés diverses.",
    "text4": "Des problèmes de santé découlent souvent de ces ruptures, de ces conditions de vie passées. Elles rendent moins à même les personnes de repérer et soigner ces problèmes de santé, qui s’aggravent et se cumulent.",
    "titleSecondPart": "Agir par les Activités physiques adaptées :",
    "textSecondPart1": "<strong>Les activités physiques adaptées sont dispensées pour des personnes en difficulté sociale, à des fins de prévention, de réinsertion, d’éducation et/ou de participation sociale. </strong>",
    "textSecondPart2": "<strong>L ’Apa</strong> ne se réduit pas à la seule pratique d’activités physiques. En effet, nous utilisons l’activité physique comme un support qui va permettre aux individus de développer des ressources physiques, sociales et psychologiques. En ce sens, la pratique <strong>d’Apa</strong> n’est pas une finalité en soi, mais un moyen mis en place pour accompagner les personnes et les groupes concernés.",
    "textSecondPart3": "<strong>L’Apa</strong> s’inscrit alors pleinement dans le parcours de vie des individus, elle est alors utilisée comme un outil pour l’accomplissement et l’expression individuelle, et comme un facteur de communication, d’affiliation et de lien social.",
    "secondTitle": "Accompagnement type proposé par Vitaé, sport santé :",
    "firstStepTitle": "Bilan initial :",
    "stepSubTitle": "Evaluation de la santé physique, sociale, et émotionnelle",
    "firstStepText": "<strong>1er étape : <br> Atelier d’activité physique adaptée <br> </strong> - Remobiliser les aptitudes <br> - Renforcer les capacités <br> - Développer les compétences <br> - Augmenter l’estime de soi et la confiance",
    "secondStepTitle": "Bilan intermédiaire :",
    "secondStepText": "<strong>2ème étape : <br> Orienter vers une association sportive ou de loisir. <br></strong> - Favoriser et faciliter l’inclution <br> - Réduire les frontières sociales <br> - Développer une culture commune",
    "thirdStepTitle": "Bilan Final :"
  }
}
</i18n>

<style scoped>

@media screen and (max-width: 1500px) {
  .textLeft {
    text-align: left;
    padding-left: 100px;
    padding-right: 100px;
  }
  .textLeftSecondPart {
    text-align: left;
    padding-right: 100px;
  }
}
@media screen and (min-width: 1500px) {
  .textLeft {
    text-align: left;
    padding-left: 200px;
    padding-right: 200px;
  }
  .textLeftSecondPart {
    text-align: left;
    padding-right: 200px;
  }
}

.secondTitle {
  padding-top: 50px;
  padding-bottom: 50px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.secondTitleMobile {
  font-size: 18px;
  padding: 50px;
  color: #1579A7;
  font-family: Comfortaa, sans-serif;
}

.textMobile {
  text-align: left;
  font-size: 14px;
}

.imgPeople {
  margin-top: -50px;
  margin-right: 250px;
}

.imgPeopleMobile {
  margin-top: -20px;
}

.text2 {
  color: #57939E;
}

.text1{
  color: #707070;
}

.sectionLeft {
  margin-bottom: 30px;
  vertical-align: center;
  text-align: center;
}

.secondPart {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #D5EEF4;
  color: #1579A7;
}

.secondPartLeft {
  padding-left: 250px;
  padding-right: 250px;
}

.secondPartLeftMobile {
  text-align: left;
}

.thirdPart {
  padding-bottom: 100px;
}

.thirdPart {
  padding-bottom: 30px;
}

.textLeftThirdPart {
  text-align: right;
}

.titleStep {
  font-family: Comfortaa, sans-serif;
  color: #57939E;
}

.titleStepMobile {
  font-size: 18px;
  font-family: Comfortaa, sans-serif;
  color: #57939E;
}

.firstStep {
  padding-bottom: 200px;
}

.secondStep {
  padding-top: 285px;
}

.secondStepMobile {
  padding-top: 50px;
}

.thirdStep {
  padding-top: 150px;
}


</style>
