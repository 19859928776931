<template>
  <div>
    <IntegrationSport v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import IntegrationSport from '@/components/desktop/Establishments/IntegrationSportComp'

  export default {
    name: 'EstablishmentForDisable',
    props: ['mobile'],
    components: {
      IntegrationSport
    },
  }
</script>
